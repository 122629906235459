import React, { useState } from "react"
import Title from "../title-component"
import styles from "../../css/contact.module.css"
import FormInput from "../form-input-component"
import { FaFacebook } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"
import ReactMapGL, { Marker, Popup } from "react-map-gl"
import logo from "../../images/logoBravoHats3Map.svg"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

const Contact = () => {
  const intl = useIntl()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [description, setdescription] = useState("")
  const [viewport, setViewport] = useState({
    latitude: -2.876422,
    longitude: -79.012539,
    width: "100vw",
    height: "50vh",
    zoom: 14,
  })
  /* 
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.791536288182!2d-79.0147166846517!3d-2.876416640240353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91cd172d130d080f%3A0x75a078aba520a8a6!2sBravo%20Hats!5e0!3m2!1ses!2sec!4v1597733974088!5m2!1ses!2sec" width="800" height="600" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
  */
  return (
    <section className={styles.contact}>
      <Title
        title={<FormattedMessage id="tituloContacto1" />}
        subtitle={<FormattedMessage id="tituloContacto2" />}
      />
      <div className={styles.contactForm}>
        <div className={styles.formControl}>
          <form action="https://formspree.io/mzbjqpzp" method="POST">
            <FormInput
              type="text"
              name="name"
              handleChange={event => setName(event.target.value)}
              label={<FormattedMessage id="label1" />}
              required
              value={name}
              isInput={true}
            />
            <FormInput
              type="email"
              name="email"
              handleChange={event => setEmail(event.target.value)}
              label={<FormattedMessage id="label2" />}
              required
              value={email}
              isInput={true}
            />
            <FormInput
              name="description"
              rows="5"
              handleChange={event => setdescription(event.target.value)}
              label={<FormattedMessage id="label3" />}
              required
              value={description}
            />

            <div className={styles.buttons}>
              <input
                type="submit"
                value={intl.formatMessage({
                  id: "submitButton",
                })}
                className={`${styles.submit} btn-blue`}
              />
            </div>
            <input
              type="hidden"
              name="_next"
              value="https://www.bravohats.com"
            />
          </form>
        </div>
        <div className={styles.datos}>
          <h3>
            <FormattedMessage id="direccion" />
          </h3>
          <p>Cuenca, Ecuador</p>
          <p>Camino a Lazareto y Gatazo</p>
          <h3>Email</h3>
          <p>info@bravohats.com</p>
          <h3>
            <FormattedMessage id="celular" />
          </h3>
          <p> + 593 992 566 284 </p>

          <ul className={styles.socialIcons}>
            <li>
              <a
                className={styles.facebook}
                href="https://www.facebook.com/Bravo-Hats-401246697155484/"
                target="_blank"
                rel="noreferrer"
                aria-label="Bravo Hats | Facebook"
              >
                <FaFacebook className={styles.facebookCirculo} />
              </a>
            </li>
            <li>
              <a
                className={styles.instagram}
                href="https://www.instagram.com/bravohats/"
                target="_blank"
                rel="noreferrer"
                aria-label="Bravo Hats | Instagram"
              >
                <FaInstagram className={styles.instagramCirculo} />
              </a>
            </li>
          </ul>
        </div>
        <div></div>
      </div>
      <div className={styles.centermap}>
        <ReactMapGL
          {...viewport}
          mapboxApiAccessToken="pk.eyJ1IjoiYXJpZWxicmF2bzEyIiwiYSI6ImNrZHpsc2lidTJ5cnAycW15Ym04bXd6bHIifQ.uYSE2_350nFiIml9fCrGEQ"
          mapStyle="mapbox://styles/arielbravo12/ckdzmkjd90j0j19p5gvk7p6d1"
          onViewportChange={viewport => {
            setViewport(viewport)
          }}
          className={styles.map}
        >
          -
          <Marker latitude={-2.876422} longitude={-79.012539}>
            <button className={styles.btnMap} aria-label="ubicacion">
              <img
                src={logo}
                alt={<FormattedMessage id="ubicacionBravoHats" />}
              ></img>
            </button>
          </Marker>
          <Popup latitude={-2.876422} longitude={-79.012539}>
            <h6>Bravo Hats - Genuine Panama Hat</h6>
          </Popup>
        </ReactMapGL>
      </div>
    </section>
  )
}

export default Contact
