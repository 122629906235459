import React from "react"
import Layout from "../components/layout-component"
import StyledHero from "../components/StyledHero"
import ContactComponent from "../components/Contact/contact-component"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Contact = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        title={`${intl.formatMessage({ id: "titleContacto" })}`}
        description={`${intl.formatMessage({ id: "descContacto" })}`}
        lang={intl.locale}
      />
      <StyledHero img={data.contact.childImageSharp.fluid} />
      <ContactComponent />
    </Layout>
  )
}
export const query = graphql`
  query {
    contact: file(relativePath: { eq: "fondoSombrero8.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Contact
