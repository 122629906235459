import React from "react"
import "../css/form-input.scss"

const FormInput = ({ handleChange, label, isInput, ...otherProps }) => (
  <div className="group">
    {isInput ? (
      <input className="form-input" onChange={handleChange} {...otherProps} />
    ) : (
      <textarea
        className="form-input"
        onChange={handleChange}
        {...otherProps}
      />
    )}
    {label ? (
      <label
        className={`${
          otherProps.value.length ? "shrink" : ""
        } form-input-label`}
      >
        {label}
      </label>
    ) : null}
  </div>
)

export default FormInput
